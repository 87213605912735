import React from "react";
import { useSuggestions } from 'ui'
import * as styles from './app.module.scss';

function App() {
    const translationState = (window as any).app.preloadState.translation;
    const preloadState = (window as any).app.preloadState.headerSearchBox;
    const { results, onFocus, onBlur, onInput, onKeyUp, value } = useSuggestions();

    return (
        <>
            <input id="HeaderSearchBox" autoComplete="off" onKeyUp={onKeyUp} spellCheck="false" onInput={onInput} onFocus={onFocus} onBlur={onBlur} type="text" placeholder={translationState["headerSearchBox.placeholder"] ?? " "} />
            {results.length > 0 &&
                <div className={styles.machineHeaderSearchBoxResults}>
                    {results.map(result => (
                        <a key={result.itemNumber} href={result.url}>
                            <span className="font-weight-bold">{result.displayName}</span> - <span className={"d-inline-block firstLetterCapital"}>{translationState["sharedTranslations.articleNo"]}: {result.itemNumber}</span>
                        </a>
                    ))}
                    <a href={`${preloadState.searchResultPage}?query=${value}`}>
                        <span className="font-weight-bold">{translationState["machineSalesCommon.showAll"]}</span>
                    </a>
                </div>}
        </>
    )
}

export default App;
