import { useState, useMemo } from 'react'
import { debounce } from "../utilities/debounce";

export const useSuggestions = () => {
    const preloadState = (window as any).app.preloadState.headerSearchBox;
    const [value, setValue] = useState<string>("");
    const [results, setResults] = useState<any[]>([]);

    const onFocus = () => {
        document.getElementsByTagName("body")[0].classList.add("blurring");
    }

    const onKeyUp = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            window.location.assign(`${preloadState.searchResultPage}?query=${value}`)
        }
    }

    const onBlur = () => {
        setTimeout(() => {
            setResults([]);
            document.getElementsByTagName("body")[0].classList.remove("blurring");
        }, 200);
    }

    const onInput = useMemo(() => debounce(async (e: React.FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        setValue(value);
        if (value === "") {
            setResults([]);
            return;
        }
        const response = await fetch(`${preloadState.searchResultApi}?searchQuery=${value}`, {
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        const data = await response.json();
        setResults(data);
    }, 200), []);

    const debouncedInput = onInput;

    return {
        results,
        onFocus,
        onBlur,
        onKeyUp,
        value,
        onInput: debouncedInput
    };
}
