// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ye0X8PWS3VEetXZhn8Da{width:100%;padding-top:36px;z-index:998;position:absolute;top:26px;color:#f2f2f0;background-color:#434b4f;border-radius:0 0 7px 7px;box-shadow:0px 14px 14px rgba(0,0,0,.2);overflow:hidden}@media(min-width: 0){.ye0X8PWS3VEetXZhn8Da a{padding:6px 12px}}@media(min-width: 400px){.ye0X8PWS3VEetXZhn8Da a{padding:6px 12px}}@media(min-width: 768px){.ye0X8PWS3VEetXZhn8Da a{padding:7px 14px}}.ye0X8PWS3VEetXZhn8Da a{cursor:pointer;display:block}.ye0X8PWS3VEetXZhn8Da a:hover{background-color:#383e42}.notLoggedIn .ye0X8PWS3VEetXZhn8Da{color:#434b4f;background-color:#fff}.notLoggedIn .ye0X8PWS3VEetXZhn8Da a:hover{background-color:#f2f2f0}@media(max-width: 1024.02px){.TBeZmSW0nR1ZFZOLiXMj{color:#f2f2f0;background-color:unset;box-shadow:none;overflow:hidden}}@media(max-width: 1024.02px)and (min-width: 0){.TBeZmSW0nR1ZFZOLiXMj a{padding:12px}}@media(max-width: 1024.02px)and (min-width: 400px){.TBeZmSW0nR1ZFZOLiXMj a{padding:12px}}@media(max-width: 1024.02px)and (min-width: 768px){.TBeZmSW0nR1ZFZOLiXMj a{padding:14px}}@media(max-width: 1024.02px){.TBeZmSW0nR1ZFZOLiXMj a{cursor:pointer}.TBeZmSW0nR1ZFZOLiXMj a:hover{background-color:unset !important}}`, ""]);
// Exports
export var machineHeaderSearchBoxResults = `ye0X8PWS3VEetXZhn8Da`;
export var headerSearchBoxResults = `TBeZmSW0nR1ZFZOLiXMj`;
export default ___CSS_LOADER_EXPORT___;
